<template>
  <div>
    <!-- Search Filters (unchanged) -->
    <div class="row">
      <div class="col-12">
        <hr class="my-3" />
      </div>
    </div>

    <div class="row d-none d-md-flex">
      <div class="col-3">
        <small class="text-muted">Search by text</small>
      </div>
      <div class="col-3">
        <small class="text-muted">Search by date range</small>
      </div>
      <div class="col-3"></div>
      <div class="col-3"></div>
    </div>

    <div class="row d-block d-md-none">
      <div class="col-12">
        <small class="text-muted">Filter routes</small>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-3 my-2">
        <input
          class="form-control"
          type="text"
          v-model="searchString"
          placeholder="Name or description"
        />
      </div>
      <div class="col-12 col-md-3 my-2">
        <input
          class="form-control"
          type="date"
          v-model="startDate"
          placeholder="Start Date"
        />
      </div>
      <div class="col-12 col-md-3 my-2">
        <input
          class="form-control"
          type="date"
          v-model="endDate"
          placeholder="End Date"
        />
      </div>
      <div class="col-12 col-md-3 my-2">
        <button
          class="btn btn-primary btn-sm"
          @click="searchRecordedTrailsClicked($event, true)"
        >
          Search
        </button>
        <button class="btn btn-text btn-sm" @click="clearSearchFilters($event)">
          Clear
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <hr class="my-3" />
      </div>
    </div>

    <!-- Main list of recorded trails -->
    <div class="bg-dark p-3 rounded-lg">
      <!-- If we have recorded trails, show them -->
      <div v-if="recordedTrails.length">
        <!-- Loop -->
        <div
          v-for="(trail, index) in recordedTrails"
          :key="trail.id || index"
        >
          <div class="row">
            <!-- Thumbnail -->
            <a :href="trail.shareableUrl" class="col-12 col-md-2 d-flex align-items-center justify-content-center square p-1">
              <img
                :src="trail.thumbnailUrl"
                class="rounded img-fluid my-2"
                width="176"
                height="98"
                alt="Recorded Trail Thumbnail"
              />
            </a>

            <!-- Main details -->
            <div class="col-12 col-md-10 my-2 d-flex align-items-center justify-content-between">
              <div>
                <a :href="trail.shareableUrl"><h3 class="display-5"><b>{{ trail.title || 'Untitled Recorded Trail' }}</b></h3></a>
                <p class="text-muted">
                  <small>{{ formatDate(trail.createdAt) }}</small>
                </p>

                <p v-if="!trail.images.length" class="text-yellow" style="cursor: pointer;" @click="editRecordedTrail(trail)"><small><u>Add images?</u></small></p>

                <!-- If there's an associated trail -->
                <div v-if="trail.trail" class="associated-trail-box">
                  <div class="row no-gutters">
                    <div class="col">
                      <a :href="realTrailUrl(trail.trail)">
                        <small>
                          <strong class="text-info">Associated Trail:</strong>
                          {{ trail.trail.name }}
                          <!-- etc... -->
                        </small>
                      </a>
                    </div>
                  </div>
                </div>

                <!-- If NO associated trail -->
                <div v-else class="my-trail-placeholder" @click="editRecordedTrail(trail)">
                  <p class="text-info" style="cursor: pointer;"><small><u>Add an associated trail?</u></small></p>
                  <!-- If NO photos -->
                </div>
              </div>
              <div class="dropdown float-end align-self-start">
                <button class="btn btn-secondary dropdown-toggle d-flex align-items-center" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="material-icons">more_vert</span>
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" :href="trail.shareableUrl">View Map</a></li>
                  <li><button class="dropdown-item" @click="showFlyover(trail)">3D Preview</button></li>
                  <li><a class="dropdown-item" :href="printRecordedTrail(trail)">Print Map</a></li>
                  <li><button class="dropdown-item" @click="editRecordedTrail(trail)">Edit details</button></li>
                  <li><button class="dropdown-item" @click="cropRecordedTrail(trail)">Crop recording</button></li>
                  <li><button class="dropdown-item" @click="triggerCopyModel(trail)">Share route</button></li>
                  <li><button class="dropdown-item" @click="deleteRecordedTrail(trail)">Delete recording</button></li>
                </ul>
              </div>
            </div>
          </div>
          <!-- If this recording has photos -->
          <div v-if="trail.images && trail.images.length">
            <div class="row">
              <div v-for="(img, i) in trail.images" :key="img.id || i" class="col-4 col-md-2 square p-1">
                    <!-- If your schema is something else, adapt as needed -->
                <img
                  v-if="img.file && img.file.url"
                  :src="img.file.url"
                  class="rounded img-fluid"
                  alt="Recording photo"
                />
              </div>
            </div>
          </div>
          <!-- Divider after each item -->
          <div class="row">
            <div class="col-12">
              <hr class="my-3" />
            </div>
          </div>
        </div>
      </div>

      <!-- If no trails found -->
      <div v-else>
        <p>No trails found.</p>
      </div>

      <!-- "See More" Button -->
      <div v-if="hasMoreTrails" class="see-more d-flex justify-content-center m-0">
        <button class="btn btn-link" @click="searchRecordedTrailsClicked($event, false)">
          See More
        </button>
      </div>

      <LoadingDialog :show-loading-dialog="loading" />

      <!-- Copy/Share Modal -->
      <div id="copyRecordedModal" class="modal fade">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header d-flex justify-content-between">
              <h3 class="modal-title m-0">
                <strong>Share</strong>
              </h3>
              <button class="close btn-link" data-bs-dismiss="modal" aria-label="Close">
                <span>×</span>
              </button>
            </div>
            <div class="modal-body">
              <p class="mb-1">
                <strong>Trail Link</strong>
                <span class="text-yellow" style="display: none">(Copied!)</span>
              </p>
              <p class="mb-3">
                <small class="text-muted">
                  Anyone with this link can view your trail
                </small>
              </p>

              <div class="input-group mb-3" @click="copyToClipboard">
                <input id="recordedTrailShareInput" class="form-control" type="text" />
                <button class="btn btn-primary input-group-text" type="button">
                  <span class="material-icons">content_copy</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Recording Details Component -->
    <EditRecordingDetails />
  </div>
</template>

<script lang="ts">
import graph_client from '../utils/graphql/graph_client';
import LoadingDialog from "../utils/shared_components/LoadingDialog.vue";
import {DestroyRecording, SearchUsersRecordedTrails, UserRecordingsQuery } from "../utils/graphql/recorded-trail-graph";
import EditRecordingDetails from "../edit_recording_details/EditRecordingDetails.vue";
import moment from 'moment'

export default {
  name: 'RecordedTrails',
  components: { LoadingDialog, EditRecordingDetails },
  props: {
    formMode: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      recordedTrails: [],
      searchString: '',
      startDate: null,
      endDate: null,
      currentPage: 1,
      pageSize: 10,
      hasMoreTrails: false,
      loading: true,
    };
  },
  mounted() {
    this.searchRecordedTrails(true);
    window.reloadRecordedTrails = () => {
      this.searchRecordedTrails(true);
    };
  },
  methods: {
    selectRecordedTrail(event, trail) {
      event.preventDefault();
      window.trailSelected(trail.id);
      window.trailSelectedName(trail.title);
      window.closeVueModal();
    },
    formatDate(date) {
      return moment(date).format('LLL');
    },
    realTrailUrl(t) {
      // t is the 'trail.trail' object
      // e.g. { countrySlug: 'ireland', regionSlug: 'county-kerry', slug: 'kerry-way' }
      return `/trails/${t.countrySlug}/${t.regionSlug}/${t.slug}`;
    },
    searchRecordedTrailsClicked(event, reset = false) {
      event.preventDefault();
      this.searchRecordedTrails(reset);
    },
    searchRecordedTrails(reset = false) {
      this.loading = true;
      if (reset) {
        this.currentPage = 1;
        this.recordedTrails = [];
      }

      const variables = {
        string: this.searchString || '',
        startDate: this.startDate || null,
        endDate: this.endDate || null,
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      graph_client.request(SearchUsersRecordedTrails, variables).then((data) => {
        const newTrails = data.recordingsTextSearch || [];
        this.loading = false;

        this.recordedTrails = reset ? newTrails : this.recordedTrails.concat(newTrails);
        this.hasMoreTrails = newTrails.length === this.pageSize;

        if (this.hasMoreTrails) {
          this.currentPage += 1;
        }
      }).catch((error) => {
        console.error('Error fetching recorded trails:', error);
      });
    },
    clearSearchFilters(event) {
      event.preventDefault();
      this.searchString = '';
      this.startDate = null;
      this.endDate = null;
      this.searchRecordedTrails(true);
    },
    editRecordedTrail(trail) {
      window.showEditRecordingModal(trail.obfuscatedSlug);
    },
    cropRecordedTrail(trail) {
      const croppingUrl = `${trail.shareableUrl}/crop`;
      window.location.href = croppingUrl;
    },
    showFlyover(trail) {
      window.showMapModalForRecordedTrail(trail.id);
    },
    printRecordedTrail(trail) {
      return `/print-maps?data_ids[]=${trail.id}&data_types[]=Recording`;
    },
    triggerCopyModel(trail) {
      const trailUrl = trail.shareableUrl;
      document.getElementById('recordedTrailShareInput').value = trailUrl;
      $('#copyRecordedModal').modal('show');
    },
    deleteRecordedTrail(trail) {
      if (confirm('Are you sure you want to delete this recorded trail?')) {
        this.loading = true;
        graph_client.request(DestroyRecording, {
          input: { id: trail.id },
        }).then(() => {
          this.searchRecordedTrails(true);
        }).catch((error) => {
          console.error('Error deleting recorded trail:', error);
        });
      }
    },
    copyToClipboard() {
      const inputElement = document.getElementById('recordedTrailShareInput');
      if (inputElement) {
        inputElement.select();
        inputElement.setSelectionRange(0, 99999);

        try {
          navigator.clipboard.writeText(inputElement.value).then(() => {
            const confirmationElement = inputElement.parentElement.querySelector('.text-yellow');
            if (confirmationElement) {
              confirmationElement.style.display = 'inline';
              setTimeout(() => {
                confirmationElement.style.display = 'none';
              }, 2000);
            }
          });
        } catch (err) {
          console.error('Failed to copy text: ', err);
        }
      }
    }
  },
};
</script>

<style scoped>

.dropdown-toggle::after {
  display: none;
}

.see-more {
  margin-top: 20px;
}

.see-more button {
  padding: 10px 20px;
  font-size: 16px;
}
</style>
