<template>
  <div id="import-dialog-backdrop" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body p-5">
          <div class="import-dialog">
            <header>
              <h3><strong>Import Tracks</strong></h3>
            </header>
            <div class="file-drop-area rounded-lg p-3"
                 @drop.prevent="handleFileDrop"
                 @dragover.prevent>
              <p class="mb-3">Drag & Drop GPX or FIT files here, or click to select.</p>
              <input ref="fileInput"
                     type="file"
                     @change="handleFileChange"
                     multiple
                     accept=".gpx,.fit"
                     hidden>
              <button class="browse-button btn btn-sm btn-secondary" @click="triggerFileInput">Choose Files</button>
            </div>
            <ul class="file-list">
              <li v-for="(file, index) in files" :key="index">
                <p class="file-info text-center mb-3">{{ file.name }}</p>
              </li>
            </ul>
            <!-- Single Import Button -->
            <div class="w-100 d-flex justify-content-center mt-3">
              <button class="btn btn-sm btn-primary" v-if="files.length > 0 && !isImporting"
                      @click="importAllFiles">
                Import Files
              </button>
              <div v-else-if="isImporting" class="loading">Importing...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import GpxParser from "gpxparser";
import FitParser from 'fit-file-parser';
import graph_client from "../utils/graphql/graph_client";
import { CreateRecording } from "../utils/graphql/recorded-trail-graph";

export default {
  data() {
    return {
      files: [],
      isImporting: false, // Track if importing is in progress
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const filesAdded = Array.from(event.target.files);
      this.files.push(...filesAdded.map(file => ({
        name: file.name,
        file: file,
      })));
    },
    handleFileDrop(event) {
      const filesAdded = Array.from(event.dataTransfer.files);
      this.files.push(...filesAdded.map(file => ({
        name: file.name,
        file: file,
      })));
    },
    async importAllFiles() {
      if (this.isImporting) return;
      this.isImporting = true; // Start importing

      try {
        // Import each file sequentially
        for (const fileEntry of this.files) {
          await this.importFile(fileEntry);
        }

        // Refresh the page after all files are imported
        window.reloadRecordedTrails();
        $('#import-dialog-backdrop').modal('hide');
      } catch (error) {
        console.error('Import error:', error);
        alert(`Failed to import files: ${error.message}`);
      } finally {
        this.isImporting = false; // Reset importing state
      }
    },
    async importFile(fileEntry) {
      try {
        const file = fileEntry.file;
        const title = file.name.replace(/\.[^/.]+$/, '');
        const extension = file.name.split('.').pop().toLowerCase();
        let trackData;
        if (extension === 'gpx') {
          trackData = await this.parseGpxFile(file, title);
        } else if (extension === 'fit') {
          trackData = await this.parseFitFile(file, title);
        } else {
          throw new Error('Unsupported file type');
        }

        const variables = {
          input: {
            lineString: trackData.lineString || "",
            elapsedSeconds: trackData.elapsedSeconds || 0,
            timeStamps: trackData.timeStamps || [],
            altitudeData: trackData.altitudes?.join(",") || "",
            courseAccuracy: [],
            speedAccuracy: [],
            verticalAccuracy: [],
            horizontalAccuracy: trackData.hAccuracies || [],
            speed: trackData.speeds || [],
            course: trackData.courses || [],
            clientId: `upload-${Date.now()}`,
            createdAt: trackData.createdAt || new Date().toISOString(),
            title: trackData.title,
          }
        };

        // Execute GraphQL mutation
        await graph_client.request(CreateRecording, variables);
      } catch (error) {
        console.error('Import error:', error);
        throw error; // Re-throw the error to handle it in importAllFiles
      }
    },
    async parseGpxFile(file, title) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
          try {
            let parsed = new GpxParser();
            parsed.parse(e.target.result);
            const points = parsed.tracks[0].points.map(p => {
              if (p.time === undefined) {
                throw new Error('GPX file must have timestamps for each point');
              }
              return {
                lat: p.lat,
                lon: p.lon,
                elevation: p.elevation,
                time: p.time,
              };
            });
            let time = points[0].time;
            if (parsed.metadata && parsed.metadata.name) {
              title = parsed.metadata.name;
            }
            resolve(this.processTrackData(points, time, title));
          } catch (error) {
            reject(error);
          }
        };
        reader.readAsText(file);
      });
    },
    async parseFitFile(file, title) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            new FitParser({
              force: true,
              speedUnit: 'm/s',
            }).parse(e.target.result, (error, data) => {
              if (error) return reject(error);
              const points = data.records
                .filter(r => r.position_lat && r.position_long)
                .map(r => ({
                  lat: r.position_lat,
                  lon: r.position_long,
                  elevation: r.altitude,
                  time: r.timestamp,
                  speed: r.speed,
                  course: r.enhanced_speed || r.speed,
                  hAccuracy: r.h_accuracy,
                  vAccuracy: r.v_accuracy,
                }));
              const createdAt = points[points.length - 1].time;
              resolve(this.processTrackData(points, createdAt, title));
            });
          } catch (error) {
            reject(error);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    },
    processTrackData(points, createdAt, title) {
      let cumulativeDistance = 0;
      let previousPoint = null;
      const result = {
        lineString: 'LINESTRING (',
        altitudes: [],
        distances: [],
        timeStamps: [],
        speeds: [],
        courses: [],
        hAccuracies: [],
        vAccuracies: [],
        createdAt: createdAt || new Date(),
        title: title,
      };

      points.forEach((p, i) => {
        if (previousPoint) {
          cumulativeDistance += this.calculateDistance(previousPoint, p);
        }
        previousPoint = p;

        const ts = new Date(p.time).getTime();
        result.lineString += `${p.lon} ${p.lat} ${p.elevation || 0} ${cumulativeDistance}`;
        if (i < points.length - 1) result.lineString += ', ';

        result.altitudes.push(p.elevation || 0);
        result.distances.push(cumulativeDistance);
        result.timeStamps.push(ts);
        result.speeds.push(p.speed || 0);
        result.courses.push(p.course || 0);
        result.hAccuracies.push(p.hAccuracy || 0);
        result.vAccuracies.push(p.vAccuracy || 0);
      });

      result.lineString += ')';
      result.elapsedSeconds = parseInt((result.timeStamps[result.timeStamps.length - 1] - result.timeStamps[0]) / 1000)

      return result;
    },
    calculateDistance(a, b) {
      const R = 6371e3;
      const φ1 = a.lat * Math.PI / 180;
      const φ2 = b.lat * Math.PI / 180;
      const Δφ = (b.lat - a.lat) * Math.PI / 180;
      const Δλ = (b.lon - a.lon) * Math.PI / 180;

      const haversine = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

      return R * 2 * Math.atan2(Math.sqrt(haversine), Math.sqrt(1 - haversine));
    },
  },
};
</script>

<style scoped>

.file-drop-area {
  border: 2px dashed var(--gray-border);
  padding: 10px;
  text-align: center;
  margin: 20px 0;
}

.file-list {
  list-style-type: none;
  padding: 0;
}

.file-info {
  margin-bottom: 10px;
}

button {
  cursor: pointer;
}

.loading {
  color: #555;
}
</style>
