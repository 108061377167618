<template>
  <div id="map" class="map-container">
    <TrailDataCard/>
    <MapControls style="position: absolute; bottom: 40px; right: 20px;" />
    <MapButtons :button-clicked="buttonTapped" :buttons="buttons"/>
    <BaseMapStylesDialog />
    <EditRecordingDetails />
    <LoadingDialog :show-loading-dialog="loading" />
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import BaseMapStylesDialog from '../base_map_styles_dialog/BaseMapStylesDialog.vue';
import MapButtons from '../components/MapButtons.vue';
import MapControls from '../components/MapControls.vue';
import EditRecordingDetails from '../edit_recording_details/EditRecordingDetails.vue';
import TrailDataCard from '../trail_data_card/TrailDataCard.vue';
import graph_client from '../utils/graphql/graph_client';
import { GetRecordedTrailById } from '../utils/graphql/recorded-trail-graph';
import { HiikerMapStyle, ThreeDimensionalMapManager } from '../utils/models/MapStyles';
import { OnBoardingGoal } from '../utils/models/OnBoardingGoal';
import { RevenueCatWrapper } from '../utils/RevenueCatWrapper';
import LoadingDialog from "../utils/shared_components/LoadingDialog.vue";

export default {
  name: 'RecordingMap',
  components: {
    LoadingDialog,
    EditRecordingDetails,
    BaseMapStylesDialog,
    MapButtons,
    MapControls,
    TrailDataCard,
  },
  data() {
    return {
      loading: true,
      buttons: [
        {
          id: 0, name: 'Map Layers', icon: 'layers', enabled: false, dropIcon: true,
        },
        {
          id: 1, name: '3D maps', icon: 'landscape', enabled: false, dropIcon: false,
        },
      ],
      geojson: null,
    };
  },
  async mounted() {
    mapboxgl.accessToken = 'pk.eyJ1IjoiaGlpa2VyLW1hcHMiLCJhIjoiY2x0YThyZHppMWhmejJqbzExODBuY3dveiJ9.nBrtnXgbzxpAx_953HCB-w';
    const isPro = await RevenueCatWrapper.isProOrProPlus();
    const mapStyle = HiikerMapStyle.getCurrentStyle(isPro);
    window.map = new mapboxgl.Map({
      container: 'map',
      style: mapStyle.styleUrl,
      center: window.lonlat || [0, 0],
      zoom: 12,
    });

    // Handle resize events to ensure the map fills the screen
    window.addEventListener('resize', this.resizeMap);
    this.resizeMap();

    // Add a map on load
    window.map.on('load', async () => {
      await this.fetchData();
      this.addToMap();
    });
    window.map.on('style.load', () => {
      this.addToMap();
    });
  },
  methods: {
    resizeMap() {
      // Ensure map resizes when window size changes
      if (window.map) {
        window.map.resize();
      }
    },
    async buttonTapped(button) {
      const isProOrProPlus = await RevenueCatWrapper.isProOrProPlus();
      switch (button) {
        case 0:
          // Map Layers
          if (window.user) {
            $('#map-styles-dialog').modal('show');
          } else {
            window.onBoardingVue.launchFor(OnBoardingGoal.MapStyles);
          }
          break;
        case 1:
          // 3D maps
          if (isProOrProPlus) {
            ThreeDimensionalMapManager.toggle3DMode();
          } else {
            window.location.href = '/purchases';
          }
          break;
        default:
          break;
      }
    },
    async fetchData() {
      const response = await graph_client.request(GetRecordedTrailById, { slug: `${window.recording_id}` });
      const { recordingBySlug } = response;
      const recording = recordingBySlug;
      const geojson = JSON.parse(recording.lineString);
      this.geojson = geojson;
      window.trailCardDataRecording(recording);
    },
    addToMap() {
      if (!this.geojson) {
        return;
      }
      if (window.map.getSource('line')) {
        window.map.removeLayer('line');
        window.map.removeSource('line');
      }
      window.map.addSource('line', {
        type: 'geojson',
        data: this.geojson,
      });

      window.map.addLayer({
        id: 'line',
        type: 'line',
        source: 'line',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#a72024',
          'line-width': 8,
        },
      });

      // set the bounds
      const bounds = new mapboxgl.LngLatBounds();
      const { geometry } = this.geojson.features[0];
      geometry.coordinates.forEach((coord) => {
        bounds.extend([coord[0], coord[1]]);
      });
      window.map.fitBounds(bounds, { padding: 150 });
      this.loading = false;
    },
  },
  beforeDestroy() {
    // Clean up event listener when component is destroyed
    window.removeEventListener('resize', this.resizeMap);

    // Clean up map resources
    if (window.map) {
      // Remove sources and layers
      if (window.map.getSource('line')) {
        window.map.removeLayer('line');
        window.map.removeSource('line');
      }

      // Remove the map instance
      window.map.remove();
      window.map = null;
    }
  },
};
</script>

<style scoped>
.map-container {
  width: 100%;
  height: calc(90vh);
  position: relative;
}

@media (max-width: 520px){
  .map-buttons-container{
    bottom: 190px !important;
  }
}
</style>
