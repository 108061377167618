<template>
  <div class="crop-elevation-profile">
    <canvas ref="chartCanvas" style="width: 100%; height: auto;"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  name: 'RecordingCropAppElevation',
  props: {
    // An array of elevation values (numbers)
    elevations: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    // Watch for changes to the elevations prop and update the chart accordingly.
    elevations: {
      handler(newElevations) {
        // Only update if we have valid data and a chart instance
        if (newElevations && newElevations.length > 0 && this.chart) {
          this.updateChart();
        } else if (newElevations && newElevations.length > 0 && !this.chart) {
          // If chart doesn't exist yet but we now have data, create it
          this.renderChart();
        }
      },
      deep: true,
    },
  },
  methods: {
    renderChart() {
      // Only render if we have data
      if (!this.elevations || this.elevations.length === 0) {
        return;
      }

      const canvas = this.$refs.chartCanvas;
      if (!canvas) {
        return;
      }

      const ctx = canvas.getContext('2d');
      if (ctx) {
        // Destroy existing chart if it exists
        if (this.chart) {
          this.chart.destroy();
        }

        this.chart = new Chart(ctx, {
          type: 'line',
          data: {
            // Use the array index as labels (e.g. segment number)
            labels: this.elevations.map((_, index) => index + 1),
            datasets: [
              {
                label: 'Elevation (m)',
                data: this.elevations,
                borderColor: '#fddf00',
                borderWidth: 2,
                backgroundColor: 'rgba(253,233,95,0.2)',
                fill: true,
                lineTension: 0.1,
                pointRadius: 0,       // Remove points from display
                pointHoverRadius: 0,  // Remove hover effect for points
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            devicePixelRatio: 1, // Ensures a 1:1 pixel ratio
            scales: {
              xAxes: [{
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: 'Segment',
                },
                gridLines: {
                  lineWidth: 1, // Adjust grid line thickness for x-axis
                  color: 'rgba(255,255,255,0.1)', // Optional: change grid line color
                },
                ticks: {
                  fontSize: 12,      // Optional: change label font size
                  fontColor: '#ffffff'
                }
              }],
              yAxes: [{
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: 'Elevation (m)',
                },
                gridLines: {
                  lineWidth: 1, // Adjust grid line thickness for y-axis
                  color: 'rgba(255,255,255,0.1)',
                },
                ticks: {
                  fontSize: 12,
                  fontColor: '#ffffff'
                }
              }],
            },
            legend: {
              display: false,
            },
            animation: {
              duration: 0 // Disable animation for better performance
            }
          },
        });
      }
    },
    updateChart() {
      // Only update if we have valid data and a chart instance
      if (!this.elevations || this.elevations.length === 0 || !this.chart) {
        return;
      }

      try {
        // Safely update chart data
        this.chart.data.labels = this.elevations.map((_, index) => index + 1);
        this.chart.data.datasets[0].data = this.elevations;

        // Use a safer update method that's less prone to errors
        this.chart.update({
          preservation: true  // Preserve animations
        });
      } catch (error) {
        console.error('Error updating chart:', error);

        // If update fails, try to recreate the chart
        this.renderChart();
      }
    },
  },
  beforeDestroy() {
    // Clean up chart instance when component is destroyed
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }
};
</script>

<style scoped>
.crop-elevation-profile {
  position: relative;
  bottom: 0;
  height: 25vh;
  padding: 1em;
  width: 100%;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.74);
}
</style>
