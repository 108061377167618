<template>
  <div ref="mapContainer" class="map-container"></div>
</template>

<script>
import mapboxgl from 'mapbox-gl';

export default {
  name: 'RecordingCropAppMap',
  props: {
    // Expect a GeoJSON string containing your route data
    lineString: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      map: null,
    };
  },
  mounted() {
    mapboxgl.accessToken = 'pk.eyJ1IjoiaGlpa2VyLW1hcHMiLCJhIjoiY2x0YThyZHppMWhmejJqbzExODBuY3dveiJ9.nBrtnXgbzxpAx_953HCB-w';
    window.recording_crop_app_map = new mapboxgl.Map({
      container: this.$refs.mapContainer,
      style: 'https://hiiker.app/mapbox_style_jsons/standard.json',
      center: window.lonlat || [0, 0],
      zoom: 12,
    });

    window.recording_crop_app_map.on('load', () => {
      // Only add the layer if we have valid data.
      if (this.lineString && this.lineString.trim() !== '') {
        this.addRouteLayer(this.lineString);
      }
    });
  },
  watch: {
    lineString(newVal) {
      if (newVal && newVal.trim() !== '') {
        try {
          const geoJson = JSON.parse(newVal);
          this.addRouteLayer(this.lineString);
          this.fitToRoute(geoJson);
        } catch (error) {
          console.error('Error updating GeoJSON:', error);
        }
      }
    },
  },
  methods: {
    addRouteLayer(lineStringData) {
      if (!lineStringData || lineStringData.trim() === '') {
        console.warn('No valid GeoJSON provided.');
        return;
      }
      try {
        const geoJson = JSON.parse(lineStringData);
        if (window.recording_crop_app_map.getSource('route')) {
          window.recording_crop_app_map.getSource('route').setData(geoJson);
          return;
        }
        window.recording_crop_app_map.addSource('route', {
          type: 'geojson',
          data: geoJson,
        });
        window.recording_crop_app_map.addLayer({
          id: 'route-layer',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#ff7e5f',
            'line-width': 4,
          },
        });
        this.fitToRoute(geoJson);
      } catch (error) {
        console.error('Error parsing GeoJSON:', error);
      }
    },
    fitToRoute(geoJson) {
      const { coordinates } = geoJson.features[0].geometry;
      if (coordinates && coordinates.length > 0) {
        // Extract only the longitude and latitude.
        const routeCoords = coordinates.map((coord) => [coord[0], coord[1]]);
        const bounds = routeCoords.reduce(
          (bounds, coord) => bounds.extend(coord),
          new mapboxgl.LngLatBounds(routeCoords[0], routeCoords[0]),
        );
        window.recording_crop_app_map.fitBounds(bounds, { padding: 100 });
      }
    },
  },
  beforeDestroy() {
    if (window.recording_crop_app_map) {
      window.recording_crop_app_map.remove();
    }
  },
};
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 65vh;
}
</style>
