<template>
  <div class="container-fluid position-relative">
    <!-- Render slider only when recording data is available -->
    <div v-if="recordingPointsCount > 0" class="slider-container">
      <vue-slider
          v-model="selectedRange"
          :min="0"
          :max="recordingPointsCount - 1"
          :tooltip="'always'"
          :dot-size="16"
          :enable-cross="false"
          :minRange="1"
      />
    </div>
    <!-- Optionally show a loading message when no data is available -->
    <div v-else>
      <LoadingDialog :show-loading-dialog="loading" />
    </div>

    <div class="crop-button-group">
      <button class="btn btn-primary save-crop m-1" @click="saveTrailData">Save</button>
    </div>
    <RecordingCropAppMap :lineString="croppedLineString" />
    <RecordingCropAppElevation :elevations="croppedElevations" :class="croppedElevations" />
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/dist-css/vue-slider-component.css';
import 'vue-slider-component/theme/antd.css';
import 'vue-slider-component/theme/default.css';
import RecordingCropAppMap from './components/RecordingCropAppMap.vue';
import RecordingCropAppElevation from './components/RecordingCropAppElevation.vue';
import graph_client from '../utils/graphql/graph_client';
import { GetRecordedTrailById, UpdateRecording } from '../utils/graphql/recorded-trail-graph';
import LoadingDialog from "../utils/shared_components/LoadingDialog.vue";

export default {
  name: 'CropRecording',
  components: {
    VueSlider,
    RecordingCropAppMap,
    RecordingCropAppElevation,
    LoadingDialog,
  },
  data() {
    return {
      loading: false,
      recording: null,
      selectedRange: [0, 0],  // (start, end)
    };
  },
  computed: {
    /**
     * Determines total number of coordinate points in the recording
     */
    recordingPointsCount() {
      if (
          this.recording &&
          this.recording.recordingBySlug &&
          this.recording.recordingBySlug.lineString
      ) {
        try {
          const geoJson = JSON.parse(this.recording.recordingBySlug.lineString);
          return geoJson.features[0].geometry.coordinates.length;
        } catch (error) {
          console.error('Parsing error:', error);
          return 0;
        }
      }
      return 0;
    },
    /**
     * Sliced GeoJSON string for map display (just for UI preview).
     */
    croppedLineString() {
      if (
          this.recording &&
          this.recording.recordingBySlug &&
          this.recording.recordingBySlug.lineString
      ) {
        try {
          const geoJson = JSON.parse(this.recording.recordingBySlug.lineString);
          const [start, end] = this.selectedRange;
          geoJson.features[0].geometry.coordinates =
              geoJson.features[0].geometry.coordinates.slice(start, end + 1);
          return JSON.stringify(geoJson);
        } catch (error) {
          console.error('Cropping error:', error);
          return this.recording.recordingBySlug.lineString;
        }
      }
      return '';
    },
    /**
     * Extract elevation values from cropped coords (assuming 3rd value is altitude).
     */
    croppedElevations() {
      if (
          this.recording &&
          this.recording.recordingBySlug &&
          this.recording.recordingBySlug.lineString
      ) {
        try {
          const geoJson = JSON.parse(this.recording.recordingBySlug.lineString);
          const [start, end] = this.selectedRange;
          const coordinates = geoJson.features[0].geometry.coordinates.slice(start, end + 1);
          return coordinates.map(coord => coord[2]);
        } catch (error) {
          console.error('Elevation error:', error);
          return [];
        }
      }
      return [];
    },
  },
  methods: {
    // ----------------------------------------
    // 1) Helper: Haversine distance in meters
    // ----------------------------------------
    haversineDistance(lng1, lat1, lng2, lat2) {
      // If your array is [lat, lng], swap these parameters accordingly
      const R = 6371008.8; // Earth radius in meters
      const rad = x => (x * Math.PI) / 180;

      const dLat = rad(lat2 - lat1);
      const dLng = rad(lng2 - lng1);
      const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(rad(lat1)) *
          Math.cos(rad(lat2)) *
          Math.sin(dLng / 2) * Math.sin(dLng / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c; // distance in meters
    },

    // ------------------------------------------------------
    // 2) Helper: Recalculate new distances array from coords
    // ------------------------------------------------------
    recalculateDistances(croppedCoords) {
      // We will build a distances array with the same length as croppedCoords
      // The first point's distance is 0; each subsequent point is the cumulative
      // total from the first point.

      if (!croppedCoords || croppedCoords.length === 0) {
        return [];
      }

      // Start distance array with 0 for the first point
      const newDistances = [0];

      for (let i = 1; i < croppedCoords.length; i++) {
        // Previous coordinate: [lng, lat, alt?]
        const [prevLng, prevLat] = croppedCoords[i - 1];
        // Current coordinate: [lng, lat, alt?]
        const [currLng, currLat] = croppedCoords[i];

        // Distance from the previous point in meters
        const segmentDist = this.haversineDistance(prevLng, prevLat, currLng, currLat);

        // Cumulative distance is previous cumulative + this segment
        const cumulativeDist = newDistances[i - 1] + segmentDist;
        const roundedCumulative = Math.round(cumulativeDist * 100) / 100;
        newDistances.push(roundedCumulative);
      }
      return newDistances;
    },

    // ----------------------------------------
    // 3) Save method
    // ----------------------------------------
    async saveTrailData() {
      // Confirm user wants to overwrite
      // eslint-disable-next-line no-restricted-globals
      const confirmed = confirm('Are you sure you want to save this cropped recording? This cannot be undone.');
      if (!confirmed) {
        return;
      }

      this.loading = true;
      try {
        // 1) Reference the recording data
        const recordingData = this.recording.recordingBySlug;

        // 2) Extract start/end indices
        const [start, end] = this.selectedRange;

        // 3) Parse the full GeoJSON and slice coordinates
        const geoJson = JSON.parse(recordingData.lineString);
        const croppedCoords = geoJson.features[0].geometry.coordinates.slice(start, end + 1);

        if (croppedCoords.length < 2) {
          alert('You must have at least two points to save a recording.');
          return;
        }

        // 4) Build a 2D WKT LINESTRING (removing altitude, if present).
        //    *Important*: Adjust if your data is [lat, lng] instead of [lng, lat].
        const coords2D = croppedCoords.map(([lng, lat]) => `${lng} ${lat}`).join(', ');
        const wktLineString = `LINESTRING(${coords2D})`;

        // 5) Slice out relevant arrays (time, accuracy, speed...) only if they match coordinate count
        let newTimeStamps = null;
        if (recordingData.timeStamps && recordingData.timeStamps.length === this.recordingPointsCount) {
          newTimeStamps = recordingData.timeStamps.slice(start, end + 1);
        }

        let newHorizontalAccuracy = null;
        if (recordingData.horizontalAccuracy && recordingData.horizontalAccuracy.length === this.recordingPointsCount) {
          newHorizontalAccuracy = recordingData.horizontalAccuracy.slice(start, end + 1);
        }

        let newVerticalAccuracy = null;
        if (recordingData.verticalAccuracy && recordingData.verticalAccuracy.length === this.recordingPointsCount) {
          newVerticalAccuracy = recordingData.verticalAccuracy.slice(start, end + 1);
        }

        let newSpeedAccuracy = null;
        if (recordingData.speedAccuracy && recordingData.speedAccuracy.length === this.recordingPointsCount) {
          newSpeedAccuracy = recordingData.speedAccuracy.slice(start, end + 1);
        }

        let newCourseAccuracy = null;
        if (recordingData.courseAccuracy && recordingData.courseAccuracy.length === this.recordingPointsCount) {
          newCourseAccuracy = recordingData.courseAccuracy.slice(start, end + 1);
        }

        let newSpeed = null;
        if (recordingData.speed && recordingData.speed.length === this.recordingPointsCount) {
          newSpeed = recordingData.speed.slice(start, end + 1);
        }

        let newCourse = null;
        if (recordingData.course && recordingData.course.length === this.recordingPointsCount) {
          newCourse = recordingData.course.slice(start, end + 1);
        }

        // We'll NOT slice the old distances, because we want to REPLACE them:
        // let newDistances = null; // removing the old slice code

        let newAltitudes = null;
        if (recordingData.altitudes && recordingData.altitudes.length === this.recordingPointsCount) {
          newAltitudes = recordingData.altitudes.slice(start, end + 1);
        }

        // 6) Recalculate distances from scratch (starting at zero)
        const newDistances = this.recalculateDistances(croppedCoords);

        // 7) Compute the new elapsedSeconds (naive approach)
        let newElapsedSeconds = 0;
        if (newTimeStamps && newTimeStamps.length > 1) {
          const oldestTimestamp = newTimeStamps[0];
          const newestTimestamp = newTimeStamps[newTimeStamps.length - 1];
          newElapsedSeconds = Math.floor((newestTimestamp - oldestTimestamp) / 1000);
        }

        // 8) Construct input object for updateRecording mutation
        const input = {
          id: recordingData.id,       // required for the update
          lineString: wktLineString,  // 2D WKT string
          elapsedSeconds: newElapsedSeconds,

          timeStamps: newTimeStamps,
          horizontalAccuracy: newHorizontalAccuracy,
          verticalAccuracy: newVerticalAccuracy,
          speedAccuracy: newSpeedAccuracy,
          courseAccuracy: newCourseAccuracy,
          speed: newSpeed,
          course: newCourse,
          altitudes: newAltitudes,

          // Our newly recalculated distances (cumulative from 0)
          distances: newDistances,
        };

        // Filter out keys that are null so we don’t send arrays that don’t exist
        const filteredInput = Object.keys(input).reduce((acc, key) => {
          if (input[key] !== null) {
            acc[key] = input[key];
          }
          return acc;
        }, {});

        // 9) Call the GraphQL mutation
        const response = await graph_client.request(UpdateRecording, {
          input: filteredInput,
        });

        // 10) Update local state or redirect
        if (response && response.updateRecording) {
          const recordingUrl = this.recording.recordingBySlug.shareableUrl;
          console.log('Successfully updated recording:', response.updateRecording);
          // Option: Navigate away or re-fetch data
          window.location.href = recordingUrl;
        }
      } catch (error) {
        console.error('Error saving cropped recording:', error);
      } finally {
        this.loading = false;
      }
    },

    updateCrop() {
      let [start, end] = this.selectedRange;

      // Enforce that start is always less than end
      if (start >= end) {
        // Snap the end forward if possible
        if (end < this.recordingPointsCount - 1) {
          end = start + 1;
        } else {
          // Or snap the start backward if end can't move forward
          start = end - 1;
        }
        this.selectedRange = [start, end];
      }
    },

    fetchRecording() {
      graph_client
          .request(GetRecordedTrailById, { slug: `${window.recording_id}` })
          .then(data => {
            this.recording = data;
            const count = this.recordingPointsCount;
            if (count > 0) {
              this.selectedRange = [0, count - 1];
            }
          })
          .catch(err => console.error('GraphQL error:', err));
    },
  },

  mounted() {
    this.fetchRecording();
  },
};
</script>

<style>
.slider-container {
  position: absolute;
  width: 80%;
  top: 5%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #17181d8f;
  padding: 1em 3em;
  z-index: 1;
  border-radius: 100px;
}

.vue-slider-dot {
  background: #4f4242;
  border: 3px solid #ffffff;
  border-radius: 100px;
}

.vue-slider-process {
  background-color: #fddf00;
}

.vue-slider:hover .vue-slider-process {
  background-color: #B9A500FF;
}

.vue-slider-dot-tooltip {
  background: #000;
  padding: 6px;
  border-radius: 8px;
}

.crop-button-group {
  position: absolute;
  bottom: 26vh;
  right: 1em;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}
</style>
