<template>
  <div id="edit-recording-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body p-5">
          <div class="import-dialog">
            <header>
              <h5 class="mb-3">
                <strong>Edit Recording Details</strong>
              </h5>
            </header>

            <!-- MAIN FORM -->
            <form>
              <!-- Title -->
              <div class="form-group mb-3">
                <label class="mb-2 text-muted">
                  <small>Title</small>
                </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="title"
                  placeholder="Name this recording"
                />
              </div>

              <!-- Body/Description -->
              <div class="form-group mb-3">
                <label class="mb-2 text-muted">
                  <small>Add a description</small>
                </label>
                <textarea
                  class="form-control"
                  v-model="body"
                  rows="3"
                  placeholder="Enter description"
                ></textarea>
              </div>

              <!-- Date/Time -->
              <div class="form-group mb-3">
                <label class="mb-2 text-muted" for="date"><small>Change Date</small></label>
                <input
                  type="datetime-local"
                  class="form-control"
                  v-model="dateTime"
                />
              </div>

              <!-- SELECT TRAIL SECTION -->
              <div class="form-group mb-3">
                <label class="mb-2 text-muted">
                  <small>Select a Trail (optional)</small>
                </label>

                <!-- Input for searching -->
                <div class="d-flex mb-2">
                  <input
                    type="text"
                    class="form-control"
                    v-model="searchQuery"
                    placeholder="Type a trail name..."
                  />
                  <button
                    type="button"
                    class="btn btn-secondary ml-2"
                    @click="searchTrails"
                  >
                    Search
                  </button>
                </div>

                <!-- Display search results -->
                <div class="mt-2" v-if="searchResults.length">
                  <div v-for="(trail, index) in searchResults" :key="trail.document.id">
                    <p style="cursor: pointer;" @click="selectTrail(trail)">
                      {{ trail.document.name }}
                      <span class="text-muted" style="font-size: 0.85em;">
                        ({{ trail.document.short_address }})
                      </span>
                      <hr class="my-2">
                    </p>
                  </div>
                </div>

                <!-- Show the currently-selected trail, if any -->
                <div v-if="selectedTrail" class="mt-3 form-group mb-3">
                  <div class="d-flex justify-content-between align-items-center form-control">
                    <div>
                      {{ selectedTrail.document.name }}
                    </div>
                    <!-- Button to unselect/clear the trail -->
                    <button type="button" class="btn btn-sm btn-light d-flex align-items-center" @click="clearSelectedTrail">
                      <span class="material-icons">close</span>
                    </button>
                  </div>
                </div>
              </div>

              <div v-if="selectedTrail" class="form-group mb-3">
                <label class="mb-2 text-muted">
                  <small>Trail Rating & Review</small>
                </label>

                <!-- Star Rating -->
                <div class="star-rating mb-2">
                  <span
                    v-for="star in 5"
                    :key="star"
                    class="star"
                    :class="{ filled: star <= reviewRating }"
                    @click="setReviewRating(star)"
                  >
                    &#9733;
                  </span>
                  <span class="ml-2">{{ reviewRating }}/5</span>
                </div>
              </div>

              <!-- REVIEW SECTION (Only show if a trail is selected) -->
              <div v-if="selectedTrail" class="form-group mb-3">
                <!-- Review Body -->
                <textarea
                  class="form-control"
                  v-model="reviewBody"
                  rows="2"
                  placeholder="How was your experience on this trail?"
                ></textarea>
              </div>

              <!-- ATTACH NEW IMAGES -->
              <div class="form-group mb-3">
                <label class="mb-2 text-muted">
                  <small>Attach New Images</small>
                </label>
                <input
                  id="recording-images"
                  type="file"
                  multiple
                  class="form-control-file"
                  accept="image/png, image/jpeg"
                  @change="fileInputEvent"
                  :disabled="uploading"
                />

                <!-- Progress bars & previews -->
                <div
                  v-for="file in newUploads"
                  :key="file.firebaseRefFullPath || file.name"
                  class="upload-progress"
                >
                  <div>
                    {{ file.name }}
                    <small> - {{ Math.round(file.progress) }}%</small>
                  </div>
                  <div class="progress-bar-background">
                    <div
                      class="progress-bar-foreground"
                      :style="{ width: file.progress + '%' }"
                    ></div>
                  </div>

                  <!-- SHOW THUMBNAIL PREVIEW -->
                  <img
                    v-if="file.previewUrl"
                    :src="file.previewUrl"
                    class="image-thumbnail mt-2"
                  />
                </div>
              </div>

              <!-- EXISTING IMAGES -->
              <div v-if="existingImages.length" class="form-group mb-3">
                <label class="mb-2 text-muted">
                  <small>Existing Images</small>
                </label>
                <div class="row">
                  <div class="col-3 d-flex align-items-center position-relative" v-for="(img, index) in existingImages" :key="img.id">
                    <img v-if="img.url" :src="img.url" class="img-fluid square rounded"/>
                    <button class="btn btn-sm btn-secondary p-1 text-center position-absolute d-flex align-items-center" type="button" @click="removeExistingImage(index)" style="top: .5em; right: 1.5em;">
                      <span class="material-icons">close</span>
                    </button>
                  </div>
                </div>
              </div>

              <!-- ACTION BUTTONS -->
              <button
                type="button"
                class="btn btn-primary"
                @click="updateRecording"
              >
                Save Changes
              </button>
              <button
                type="button"
                class="btn btn-secondary ml-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </form>
            <!-- END FORM -->
          </div>
        </div>
      </div>
    </div>

    <!-- LOADING DIALOG (Optional) -->
    <LoadingDialog :show-loading-dialog="loading" />
  </div>
</template>

<script lang="ts">
import graph_client from '../utils/graphql/graph_client';
import { GetRecordedTrailById, UpdateRecording } from '../utils/graphql/recorded-trail-graph';
import LoadingDialog from '../utils/shared_components/LoadingDialog.vue';

// Firebase stuff
import firebase from '../js/firebase';

// Your Typesense class
import { TypesenseResponse } from '../utils/models/TypesenseResponse';

export default {
  name: 'EditRecordingModal',
  components: { LoadingDialog },
  data() {
    return {
      // Recording fields
      title: '',
      body: '',
      dateTime: null,
      recordingId: null,
      recordingSlug: null,
      loading: false,
      recordingClientId: null,

      // Existing images loaded from the server
      existingImages: [],
      // Images user removed
      imagesMarkedForDeletion: [],
      // New images
      newUploads: [],
      uploading: false,

      // For trail searching
      searchQuery: '',
      searchResults: [], // array of { document: {...}, ... }
      selectedTrail: null, // store the selected "hit" object

      // For the user's review (if a trail is selected)
      reviewId: null,       // if there's an existing review
      reviewRating: 0,      // integer 1..5
      reviewBody: '',       // text
    };
  },
  mounted() {
    window.showEditRecordingModal = (recordingSlug) => {
      this.loading = true;
      this.recordingSlug = recordingSlug;
      this.getRecordingDetails();
      $('#edit-recording-modal').modal('show');
    };
  },
  methods: {
    /**
     * Fetch existing recording details, including any existing review
     */
    getRecordingDetails() {
      graph_client
        .request(GetRecordedTrailById, { slug: `${this.recordingSlug}` })
        .then((data) => {
          const recording = data.recordingBySlug;
          this.recordingId = recording.id;

          this.recordingClientId = recording.clientId;

          // Basic fields
          this.title = recording.title || '';
          this.body = recording.body || '';

          // Date/time
          const date = new Date(recording.createdAt);
          const year = date.getFullYear();
          const month = ('0' + (date.getMonth() + 1)).slice(-2);
          const day = ('0' + date.getDate()).slice(-2);
          const hours = ('0' + date.getHours()).slice(-2);
          const minutes = ('0' + date.getMinutes()).slice(-2);
          this.dateTime = `${year}-${month}-${day}T${hours}:${minutes}`;

          // Existing images
          this.existingImages = (recording.images || []).map((img) => ({
            id: img.id,
            url: img.file?.url || '',
            firebaseImageReference: img.firebaseImageReference,
            _destroy: false,
          }));

          // If there's an already-attached trail
          if (recording.trail) {
            this.selectedTrail = {
              document: {
                id: recording.trail.id,
                name: recording.trail.name,
                shortAddress: recording.trail.shortAddress,
              },
            };
          } else {
            this.selectedTrail = null;
          }

          // If there's an existing review
          if (recording.review) {
            this.reviewId = recording.review.id;
            this.reviewRating = recording.review.rating || 0;
            this.reviewBody = recording.review.body || '';
          } else {
            this.reviewId = null;
            this.reviewRating = 0;
            this.reviewBody = '';
          }

          this.loading = false;
        })
        .catch((error) => {
          console.error('Error fetching recording:', error);
          this.loading = false;
        });
    },

    /**
     * Remove an existing image from the UI
     */
    removeExistingImage(index) {
      const removedImg = this.existingImages[index];
      this.existingImages.splice(index, 1);
      this.imagesMarkedForDeletion.push({
        id: removedImg.id,
        firebaseImageReference: removedImg.firebaseImageReference,
        _destroy: true,
      });
    },

    /**
     * Upload new images to Firebase
     */
    async fileInputEvent(event) {
      const selectedFiles = Array.from(event.target.files);
      if (!selectedFiles.length) return;

      // Validate file types
      const allowedTypes = ['image/jpeg', 'image/png'];
      const validFiles = selectedFiles.filter(file =>
        allowedTypes.includes(file.type)
      );
      if (validFiles.length < selectedFiles.length) {
        alert('Some files were not valid JPEG or PNG images and have been ignored.');
      }
      if (!validFiles.length) {
        event.target.value = '';
        return;
      }

      this.uploading = true;

      // Local preview
      validFiles.forEach(file => {
        file.progress = 0;
        file.previewUrl = URL.createObjectURL(file);
        this.newUploads.push(file);
      });

      // Upload each file
      for (const file of validFiles) {
        try {
          const userId = window.user.firebase_user_id;
          const firebaseRefEnding = `web------${userId}---recording---${this.recordingId}---${file.name}------${new Date().toISOString()}.jpg`;
          const storageRef = firebase.storage().ref().child('rails_upload_end_point').child(firebaseRefEnding);

          file.firebaseRefFullPath = storageRef.fullPath;
          file.progress = 0;

          const uploadTask = storageRef.put(file);

          await new Promise((resolve, reject) => {
            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                file.progress = progress;
              },
              (error) => reject(error),
              () => resolve()
            );
          });

          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          file.downloadURL = downloadURL;
          file.progress = 100;
        } catch (error) {
          console.error('Error uploading file:', file.name, error);
        }
      }

      this.uploading = false;
      event.target.value = '';
    },

    /**
     * SEARCH TRAILS
     */
    async searchTrails() {
      this.searchResults = [];
      try {
        const response = await TypesenseResponse.get(this.searchQuery, null, null, 1, 10);
        if (response && response.hits) {
          this.searchResults = response.hits;
        }
      } catch (error) {
        console.error('Error searching trails:', error);
      }
    },

    /**
     * Called when the user clicks a trail in the search results
     */
    selectTrail(trail) {
      this.selectedTrail = trail;
      this.searchResults = [];

      // If there's no existing review rating, default to 5
      // (User can still change it after.)
      if (!this.reviewId && this.reviewRating === 0) {
        this.reviewRating = 5;
      }
    },

    makeid(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    },

    clearSelectedTrail() {
      this.selectedTrail = null;
      // Also clear the review fields if user unselects the trail
      this.reviewId = null;
      this.reviewRating = 0;
      this.reviewBody = '';
    },

    /**
     * Update star rating
     */
    setReviewRating(star) {
      this.reviewRating = star;
    },

    /**
     * Build & send the GraphQL update
     */
    updateRecording() {
      this.loading = true;
      const formattedDate = new Date(this.dateTime).toISOString();

      // Merge images
      const keptExistingAttributes = this.existingImages.map((img) => ({
        id: img.id,
        firebaseImageReference: img.firebaseImageReference,
        _destroy: false,
      }));
      const removedAttributes = this.imagesMarkedForDeletion;
      const newAttributes = this.newUploads
        .filter((f) => f.progress === 100)
        .map((f) => ({
          firebaseImageReference: f.firebaseRefFullPath,
          parentClientId: this.recordingClientId,
          clientId: this.makeid(12)
        }));

      const imagesAttributes = [
        ...keptExistingAttributes,
        ...removedAttributes,
        ...newAttributes,
      ];

      // If user selected a trail, pass its ID; otherwise null
      const trailId = this.selectedTrail
        ? this.selectedTrail.document.id
        : null;

      // Build review object if a trail is selected
      let reviewInput = null;
      if (this.selectedTrail) {
        reviewInput = {
          id: this.reviewId,
          rating: this.reviewRating,
          body: this.reviewBody,

          // In case your server needs it:
          // trailId: this.selectedTrail.document.id
        };
      }

      const input = {
        id: this.recordingId,
        title: this.title,
        body: this.body,
        createdAt: formattedDate,
        imagesAttributes,
        trailId,
      };

      // If there's a review, attach it
      if (reviewInput) {
        input.review = reviewInput;
      }

      graph_client
        .request(UpdateRecording, { input })
        .then(() => {
          this.loading = false;
          this.resetValues();
          if (window.reloadRecordedTrails) {
            window.reloadRecordedTrails();
          }
          $('#edit-recording-modal').modal('hide');
        })
        .catch((error) => {
          console.error('Error updating recording:', error);
          this.loading = false;
        });
    },

    /**
     * Reset all local data
     */
    resetValues() {
      this.title = '';
      this.body = '';
      this.dateTime = null;
      this.recordingId = null;

      this.existingImages = [];
      this.imagesMarkedForDeletion = [];
      this.newUploads = [];

      this.searchQuery = '';
      this.searchResults = [];
      this.selectedTrail = null;

      // Reset review
      this.reviewId = null;
      this.reviewRating = 0;
      this.reviewBody = '';
    },
  },
};
</script>

<style scoped>
/* Minimal styling - adapt to your needs */

.existing-images {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 8px;
}

.existing-image {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.image-thumbnail {
  max-width: 120px;
  max-height: 100px;
  object-fit: cover;
  margin-bottom: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.upload-progress {
  margin-top: 8px;
}

.progress-bar-background {
  background: #eee;
  width: 100%;
  height: 6px;
  margin-top: 4px;
  position: relative;
  border-radius: 3px;
}

.progress-bar-foreground {
  height: 6px;
  background: #428bca;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 3px;
}

/* Simple star rating styling */
.star-rating {
  display: inline-block;
}
.star {
  font-size: 1.5em;
  color: #ccc;
  cursor: pointer;
  margin-right: 4px;
}
.star.filled {
  color: #fddf00; /* gold */
}

.loading {
  color: #555;
}
</style>
